import React from "react";
import {
  Typography, Box, Stack, Grid,
} from "@mui/material";
import styled from "@emotion/styled";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import contactImage from "../../images/investors/contact.png";
import ImageCard from "../../components/ImageCard";
import ModalSuscribe from "../../components/ModalSuscribe";

function InvestorsContactPage() {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <>
      <NewLayout byDefault apps="investors.contact">
        <Container pb={8}>
          <Typography mt={3} variant="h2">
            Contact
          </Typography>
          <Box mt={{ xs: 3, sm: 7 }}>
            <Grid container spacing={4} alignItems="start">
              <Grid item xs={12} sm={6}>
                <StyledStack spacing={2}>
                  <Typography>
                    If you have questions about the Allfunds share or other
                    topic related to a potential investment in Allfunds Group
                    plc, feel free to contact our Investor Relations contacts
                    in Madrid below:
                  </Typography>
                  <Box>
                    <Typography>Allfunds Investor Relations</Typography>
                    <Typography>C/ de los Padres Dominicos, 7</Typography>
                    <Typography>28050 Madrid</Typography>
                    <Typography>T. +34 91 274 64 00</Typography>
                    <Typography>E. investors@allfunds.com</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Carlos Berastain</Typography>
                    <Typography>
                      Global Head of Investor Relations
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Javier Goyeneche</Typography>
                    <Typography>
                      Investor Relations Officer
                    </Typography>
                  </Box>
                </StyledStack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ImageCard
                  title="Alerts"
                  description="Subscribe to our distribution list and news"
                  image={contactImage}
                  linkTitle="Subscribe"
                  onClick={() => setOpenModal(true)}
                />
              </Grid>
            </Grid>
          </Box>
          {/* <Box mt={10}>
            <Typography variant="h3">
              Our Offices
            </Typography>
            <Box mt={3}>
              <Stack mt={3} spacing={2}>
                {officeKinds().map(({ kind, offices, extraLabel }) => (
                  <BoxDetails title={kind}>
                    <Grid container spacing={2}>
                      {offices.map(({ label, details }) => (
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="h5">{label} {extraLabel}</Typography>
                          {details}
                        </Grid>
                      ))}
                    </Grid>
                  </BoxDetails>
                ))}
              </Stack>
            </Box>
          </Box> */}
        </Container>
      </NewLayout>
      {openModal && <ModalSuscribe onClose={() => setOpenModal(false)} />}
    </>
  );
}

const StyledStack = styled(Stack)`
  > p, * p {
    font-size: 16px;
  }
`;

export default InvestorsContactPage;
